import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import { fetchCaseImportTemplates } from 'src/actions/caseImportTemplateActions';
import { fetchCategories } from 'src/actions/categoryActions';
import { fetchChats } from 'src/actions/chatActions';
import { fetchEnvSettings, fetchFeatureFlags, fetchPhoneConfigurations } from 'src/actions/configActions';
import { setInitialRequestToLoading } from 'src/actions/initActions';
import { fetchLinkLists } from 'src/actions/linkListActions';
import { fetchAutoSuggestions } from 'src/actions/suggestionActions';
import { fetchTags } from 'src/actions/tagActions';
import {
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTicketTypes,
  fetchTitleTemplates
} from 'src/actions/ticketsActions';
import { fetchPersonalData, fetchRoles, fetchUsers } from 'src/actions/userActions';
import { fetchWebhooks } from 'src/actions/webhooksActions';

import type { InitialRequestState } from './InitialLoad';

interface InitialLoadDescriptionProps {
  isAdmin: boolean;
  initialRequest: InitialRequestState;
}

const InitialLoadDescription: FC<InitialLoadDescriptionProps> = ({ isAdmin, initialRequest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchAgain = (reqState: InitialRequestState) => {
    dispatch(setInitialRequestToLoading(reqState.type));
    switch (reqState.type) {
      case 'users':
        dispatch(fetchUsers());
        break;
      case 'ticketTypes':
        dispatch(fetchTicketTypes());
        break;
      case 'channelTypes':
        dispatch(fetchChannelTypes());
        break;
      case 'responseTemplates':
        dispatch(fetchResponseTemplates());
        break;
      case 'titleTemplates':
        dispatch(fetchTitleTemplates());
        break;
      case 'linkLists':
        dispatch(fetchLinkLists());
        break;
      case 'tags':
        dispatch(fetchTags());
        break;
      case 'categories':
        dispatch(fetchCategories());
        break;
      case 'personalData':
        dispatch(fetchPersonalData());
        break;
      case 'envSettings':
        dispatch(fetchEnvSettings());
        break;
      case 'featureFlags':
        dispatch(fetchFeatureFlags());
        break;
      case 'roles':
        dispatch(fetchRoles());
        break;
      case 'caseImportTemplates':
        dispatch(fetchCaseImportTemplates());
        break;
      case 'PhoneConfigurations':
        dispatch(fetchPhoneConfigurations());
        break;
      case 'chatStatuses':
        dispatch(fetchChats());
        break;
      case 'autoSuggestions':
        dispatch(fetchAutoSuggestions());
        break;
      case 'webhooks': {
        if (isAdmin) {
          dispatch(fetchWebhooks());
        } else {
          console.error('Only admin ca see webhooks!');
        }
        break;
      }
      default:
        console.error('Could not fetch again this type!');
    }
  };

  const onClick = useCallback(() => fetchAgain(initialRequest), [initialRequest]);

  if (initialRequest.isCompleted) {
    return t('INIT_LOAD_REQUEST_STATE_COMPLETED');
  } else if (initialRequest.isLoading) {
    return t('INIT_LOAD_REQUEST_STATE_LOADING');
  } else if (initialRequest.error !== null) {
    return (
      <a onClick={onClick}>
        <Icon name="redo" size="small" />
        {t('INIT_LOAD_REQUEST_STATE_ERROR')}
      </a>
    );
  }

  return t('INIT_LOAD_REQUEST_STATE_UNKNOWN');
};

export default React.memo(InitialLoadDescription);
