import iziToast from 'izitoast';
import { get, isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Comment, Icon, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import AttachmentCard from '../Attachments/AttachmentCard';
import ChannelType from '../CommentIconContent/ChannelType';
import UserAvatar from '../User/UserAvatar';
import UserPopup from '../User/UserPopup';
import { ModalWindow } from '../Versions/DisplayWindow';
import AIFunctionButtons from './AIPromptButton';
import CommentMetadata from './CommentMetadata';
import CommentText from './CommentText';
import ExternalCommentPopup from './ExternalCommentPopup';
import HelperComment from './HelperComment';
import FeatureFlags from 'src/api/FeatureFlags';
import { getCommentAttachmentsIds, getCommentSender } from 'src/Components/Utilities/comments';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { iconName } from 'src/Utilities/iconName';
import { hasImageExtension } from 'src/Utilities/images';

import type { Channel } from 'src/types/Channel';
import type { Attachment, Ticket, Comment as TicketComment } from 'src/types/Ticket';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { User } from 'src/types/User';

import './UserComment.css';

interface UserCommentProps extends TicketComment {
  user: User;
  task: Ticket;
  taskId: string;
  channels: Channel[];
  title: string | null;
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  botButtonClickedState: undefined | TicketComment;
  isLastComment?: boolean;
  isLastExternalComment?: boolean;
}

const UserComment: FC<UserCommentProps> = (props) => {
  const { t } = useTranslation();
  const [isCommentExpanded, setCommentExpanded] = useState(false);
  const [isIntegratorUserReply, setIntegratorUserReply] = useState(false);
  const [isMetadataSourceOpen, setMetadataSourceOpen] = useState(false);
  const [isImageAccordionOpen, setImageAccordionOpen] = useState(false);
  const [chatComment, setChatComment] = useState(false);
  const [commentSentReceivedClass, setCommentSentReceivedClass] = useState<
    'sentComment' | 'receivedComment' | 'suggestionComment' | ''
  >('');
  const [channelTypeName, setChannelTypeName] = useState<string>('');
  const [suggestionComment, setSuggestionComment] = useState(true);

  useEffect(() => {
    /**
     * Check if incoming comment comes from integrator
     */
    if (
      [ChannelType.Chat, ChannelType.Facebook, ChannelType.Giosg, ChannelType.CustomerPortal].includes(
        props.channel!
      ) &&
      props.direction === 'in' &&
      props.task.entities.length
    ) {
      const targetEntity = props.task.entities[0].data;

      if (targetEntity?.firstName || targetEntity?.lastName) {
        setIntegratorUserReply(true);
      }
    }
  }, [props.channel, props.direction, props.task.entities.length]);

  useEffect(() => {
    if (props.type !== 'suggestion') {
      setSuggestionComment(false);
      switch (props.direction) {
        case 'out':
          setCommentSentReceivedClass('sentComment');
          break;
        case 'in':
          setCommentSentReceivedClass('receivedComment');
          break;
        default:
          setCommentSentReceivedClass('');
          break;
      }
    }

    const channelTypeToName = {
      [ChannelType.Email]: 'email',
      [ChannelType.Sms]: 'sms',
      [ChannelType.Internal]: 'internal',
      [ChannelType.Chat]: 'chat',
      [ChannelType.Webform]: 'webform',
      [ChannelType.Other]: 'other'
    };

    try {
      setChannelTypeName(channelTypeToName[props.channel ?? ChannelType.Other]);
    } catch (_) {
      setChannelTypeName('other');
    }

    setChatComment(
      ['customer_closed_chat', 'agent_closed_chat', 'chat_was_closed_automatically', 'user_closed_chat'].some(
        (tag) => props.content?.indexOf(`[${tag}]`) === 0
      )
    );
  }, []);

  const getCustomerInfoFields = () =>
    props.ticketTypes
      .find((ticketType) => ticketType.name === props.task.taskType)
      ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? [];

  const getAttachmentsList = () => {
    const source: Attachment[] = !isEmpty(get(props, ['metaData', 'originalTicketData', 'data', 'attachments']))
      ? get(props, ['metaData', 'originalTicketData', 'data', 'attachments'], [])
      : get(props, ['metaData', 'attachments'], []);

    const taskAttachments = get(props, ['task', 'attachments']);
    const commentAttachmentsFilenames: string[] = source.map((att: any) => att.fileName || att.filename);
    const commentAttachmentsCIDs: string[] = source.map((att) => String(att.cId || att['cid']).replace(/\s/g, ''));

    return taskAttachments.filter((taskAttachment) =>
      taskAttachment.cId
        ? commentAttachmentsCIDs.includes(String(taskAttachment.cId))
        : commentAttachmentsFilenames.includes(taskAttachment.fileName)
    );
  };

  const attachmentView = () => {
    const attachmentFiles = getAttachmentsList().filter((attachment) => !hasImageExtension(attachment.fileName));
    if (attachmentFiles.length) {
      return (
        <div className="userComment__attachmentCardWrapper">
          {attachmentFiles.map((attachment) => (
            <AttachmentCard attachment={attachment} attachments={attachmentFiles} key={attachment.id} />
          ))}
        </div>
      );
    }
    return null;
  };

  const attachmentImagesView = () => {
    const attachmentImages = getAttachmentsList().filter((attachment) => hasImageExtension(attachment.fileName));
    if (attachmentImages.length) {
      return (
        <Accordion>
          <Accordion.Title active={isImageAccordionOpen} onClick={() => setImageAccordionOpen(!isImageAccordionOpen)}>
            <Icon name="dropdown" />
            {t('IMAGES')}
          </Accordion.Title>
          <Accordion.Content active={isImageAccordionOpen} className="userComment__attachmentCardWrapper">
            {attachmentImages.map((attachment) => (
              <AttachmentCard attachment={attachment} attachments={attachmentImages} key={attachment.id} />
            ))}
          </Accordion.Content>
        </Accordion>
      );
    }

    return null;
  };

  const toggleExpand = () => {
    setCommentExpanded(!isCommentExpanded);
  };

  const getChannelData = (channelId: number) => {
    return props.channels.find((channel) => channel.id === channelId);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: t('COPIED')
    });
  };

  const renderEmailAddresses = () => {
    const { metaData } = props;

    return (
      <>
        <div className="flexBreak"></div>
        <div className="userComment__emailAddressesWrapper">
          {metaData?.to?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_ORIGINAL_RECIPIENTS')}</strong>:{' '}
              {metaData.to.map((to, index, toArray) => (
                <Popup
                  key={to}
                  position="top center"
                  trigger={
                    <span onClick={() => copyToClipboard(to)}>
                      {to} {toArray.length - 1 === index || '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}

          {metaData?.cc?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_CC')}</strong>:{' '}
              {metaData.cc.map((cc, index, ccArray) => (
                <Popup
                  position="top center"
                  trigger={
                    <span onClick={() => copyToClipboard(cc)}>
                      {cc} {ccArray.length - 1 === index || '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const { user } = props;
  const channelData = getChannelData(props.channel!);
  const originalEntityData = get(props, ['metaData', 'originalEntity'], null);
  const htmlMetaData = get(props, ['metaData', 'html']);

  const hasAttachments =
    !isEmpty(get(props, ['metaData', 'originalTicketData', 'data', 'attachments'])) ||
    !isEmpty(get(props, ['metaData', 'attachments']));

  const helperComments = props.task.comments.filter(
    (comment) => comment.type === 'helper' && comment.metaData?.parentCommentId === parseInt(props.id.substring(3), 10)
  );

  let commentSender: string = getCommentSender({
    originalEntityData,
    channelData,
    user,
    metaData: props.metaData ?? null,
    direction: props.direction ?? null,
    ticketEntities: props.task.entities,
    customerInfoFields: getCustomerInfoFields()
  });

  if (['CHAT_ENTITY', 'FORM_ENTITY', 'TAMPUURI_ENTITY', 'UNKNOWN'].includes(commentSender)) {
    commentSender = t(`COMMENT_AUTHOR_${commentSender}`);
  }

  return (
    <Comment
      className={
        (props.isLastExternalComment ? 'lightGrey' : '') + ' ' + (suggestionComment ? 'suggestionComment' : '')
      }
    >
      {isMetadataSourceOpen && (
        <ModalWindow title={t('comments.titles.source_preview')} onClickClose={() => setMetadataSourceOpen(false)}>
          <pre>{JSON.stringify(props.metaData, undefined, 4)}</pre>
        </ModalWindow>
      )}
      {![ChannelType.Internal, ChannelType.Other].includes(props.channel!) && props.direction === 'in' ? (
        <ExternalCommentPopup
          channelId={props.channel!}
          commentId={props.id}
          metaData={props.metaData}
          ticketTypes={props.ticketTypes}
          channels={props.channels}
          title={props.title}
        >
          <div style={{ marginLeft: '0.3rem', float: 'left' }}>
            <Icon
              name={iconName(channelData, props.direction!)}
              className="icon-small"
              style={{ color: channelData?.color || '#000', fontSize: '1.55rem' }}
            />
          </div>
        </ExternalCommentPopup>
      ) : (
        <UserPopup UID={user.UID} botName={props.metaData?.bot?.name} disabled={isIntegratorUserReply}>
          <div style={{ float: 'left' }}>
            <div style={{ marginRight: '7px' }}>
              <UserAvatar user={user} size="35" />
            </div>
          </div>
        </UserPopup>
      )}
      <Comment.Content
        style={{ color: channelData?.color || '', marginLeft: '3rem' }}
        className={`${commentSentReceivedClass} ${channelTypeName}CommentIcon`}
      >
        <div style={{ display: 'flex' }}>
          <div className="userComment__metadataWrapper">
            <Comment.Author as="a" onClick={() => copyToClipboard(commentSender)}>
              {isIntegratorUserReply ? (
                <>
                  <Icon name={'user'} />
                  {commentSender}
                </>
              ) : (
                commentSender
              )}
            </Comment.Author>

            <CommentMetadata
              task={props.task}
              ticketTypes={props.ticketTypes}
              comment={{
                id: props.id,
                channel: props.channel,
                title: props.title,
                metaData: props.metaData,
                created: props.created,
                content: props.content,
                direction: props.direction,
                type: props.type
              }}
              hasHTMLMetadata={!isEmpty(htmlMetaData)}
              isIntegratorUserReply={isIntegratorUserReply}
              channelData={channelData}
              htmlMetaData={htmlMetaData}
              channels={props.channels}
              channelTypeName={channelTypeName}
              senderEmails={props.senderEmails}
              setMetadataSourceOpen={() => setMetadataSourceOpen(true)}
              replyToEmailEnabled
            />

            {props.channel === ChannelType.Email && renderEmailAddresses()}
          </div>
          <div
            className="chatGptButtons__wrapper"
            style={{
              marginLeft: 'auto',
              ...(props.channel === ChannelType.Email ? { marginRight: '3px' } : {})
            }}
          >
            {FeatureFlags.isFlagOn('ENABLE_OPENAI') === true && (
              <AIFunctionButtons commentId={props.id} tinyButton basicButton />
            )}
          </div>
          {props.channel === ChannelType.Email && (
            <div style={{ marginRight: '5px' }}>
              <ErrorBoundary>
                <CommentEditorWidgetContainer
                  senderEmails={props.senderEmails}
                  created={props.created!}
                  title={props.title || ''}
                  comment={props.parsedContent ?? ''}
                  taskId={props.taskId}
                  metaData={props.metaData}
                  isHTML={htmlMetaData}
                  attachmentIds={getCommentAttachmentsIds(props.metaData, props.task)}
                  buttonsType={props.isLastExternalComment ? 'primary' : 'basic'}
                />
              </ErrorBoundary>
            </div>
          )}
        </div>

        {hasAttachments && (
          <div style={{ margin: '10px 0' }}>
            <ErrorBoundary>{attachmentView()}</ErrorBoundary>
            <ErrorBoundary>{attachmentImagesView()}</ErrorBoundary>
          </div>
        )}

        <CommentText
          parsedContent={props.parsedContent}
          isChatComment={chatComment}
          isCommentExpanded={isCommentExpanded}
          isLastComment={!!props.isLastComment}
          botButtonClickedState={props.botButtonClickedState}
          metaData={props.metaData}
          type={props.type!}
          attachments={props.task.attachments}
          toggleExpand={toggleExpand}
        />
      </Comment.Content>

      {helperComments.length ? (
        <Comment.Group className="discussionPanel__helpers">
          {helperComments.map((comment) => (
            <HelperComment comment={comment} key={comment.id} />
          ))}
        </Comment.Group>
      ) : null}
    </Comment>
  );
};

export default UserComment;
