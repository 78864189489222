import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { CSSProperties, FC, SyntheticEvent } from 'react';

import { setCaseIFrame } from 'src/actions/caseIFrameActions';
import { fieldValueReplaceStrings } from 'src/Components/Utilities/infoField';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { Field } from 'src/types/Info';

interface InfoLinkProps {
  field: Field;
  taskId?: string | number;
  fieldValue?: string;
}

const styles: CSSProperties = { overflowWrap: 'break-word' };

const InfoLink: FC<InfoLinkProps> = ({ taskId, fieldValue, field }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      if (field.openInIFrame) {
        e.preventDefault();

        if (!taskId) return;

        const task = taskIdToNumericalId(taskId);
        if (!task) return;

        dispatch(
          setCaseIFrame({ id: task, url: fieldValue as string, tabTitle: field.iFrameTabTitle ?? t('link_view') })
        );
      }
    },
    [field.openInIFrame, field.iFrameTabTitle, taskId]
  );

  const value = fieldValueReplaceStrings(fieldValue, field);

  return (
    <a style={styles} href={value} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      {field.linkText ?? fieldValue}
    </a>
  );
};

export default React.memo(InfoLink);
